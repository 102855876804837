import BaseURL from "../../data/Api/baseUrl";

const getAll = ({ page, limit, category, shop_id }) => {
  return BaseURL.get(`api/wom/v2/admin/get_products`, {
    params: {
      shop_id,
      category_id: category,
      limit,
      page,
    },
  });
};

const get = (id) => {
  return BaseURL.get(`api/wom/v2/admin/product/${id}`);
};

const create = ({
  name_en,
  name_ar,
  description_en,
  description_ar,
  images,
  price,
  category_id,
  shop_id,
  variants,
}) => {
  return BaseURL.postForm(`api/wom/v2/admin/create_product`, {
    name_en,
    name_ar,
    description_en,
    description_ar,
    images,
    price,
    category_id,
    shop_id,
    variants,
  });
};

const update = (id, data) => {
  return BaseURL.postForm(`api/wom/v2/admin/product/${id}`, data);
};

const remove = (id) => {
  return BaseURL.delete(`api/wom/v2/admin/product/${id}`);
};

const ProductService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default ProductService;
