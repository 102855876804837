import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProductService from "../services/ProductService";

const initialState = { loading: true, page: 1, data: [] };

export const retrieveProduct = createAsyncThunk(
  "product/retrieve",
  async ({ page, limit, category, shopId }) => {
    const res = await ProductService.getAll({
      shop_id: shopId,
      page,
      limit,
      category,
    });
    return res.data.products;
  }
);

export const createProduct = createAsyncThunk(
  "product/create",
  async ({
    name_en,
    name_ar,
    description_en,
    description_ar,
    images,
    price,
    category_id,
    shop_id,
    variants,
  }) => {
    try {
      const res = await ProductService.create({
        name_en,
        name_ar,
        description_en,
        description_ar,
        images,
        price,
        category_id,
        shop_id,
        variants,
      });
      return res.data;
    } catch (error) {
      throw error?.response?.data?.message || error.toString();
    }
  }
);

export const updateproduct = createAsyncThunk(
  "product/update",
  async ({ id, data }) => {
    const res = await ProductService.update(id, data);
    return res.data.product;
  }
);

export const deleteProduct = createAsyncThunk(
  "product/delete",
  async ({ id }) => {
    await ProductService.remove(id);
    console.log("id", id);
    return { id };
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createProduct.fulfilled, (state, action) => {
        // state.push(action.payload);
      })
      .addCase(retrieveProduct.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateproduct.fulfilled, (state, action) => {
        const { id, ...updatedProduct } = action.payload;
        state[id] = updatedProduct;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        const newState = { ...state }; // Create a shallow copy of the state object

        // Check if the item exists in the state
        if (newState[action.payload._id]) {
          delete newState[action.payload._id]; // Remove the item from the new state object
        }

        return newState; // Return the updated state object
      });
  },
});

export default productSlice.reducer;
